@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300&family=Poppins:wght@200;300;400;500;600;700&family=Quicksand&display=swap');
body {
  background: #F5F0E1;
  margin: 0;
  /* padding: 1rem 3rem; */
 font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply text-Dark-primary
}

::placeholder{
  color:#fffefe
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.shadow{
  box-shadow: 0 0 30px #f3d162;
}
